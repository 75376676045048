/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, {
  //  memo,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react"
// import { dashboardTrail } from "../../../utils/dashboardBreadcrumb"
// import { Tab } from "@headlessui/react"
import { Tabs } from "../../../components/Tabs/TabElements"
import StockListTable from "../../../components/Table/Inventory/StockList/StockListTable"
import {
  //  getStockListing,
  postStockListing,
  updateUserStock,
  // getMyListing,
  // getMyListingPagination,
} from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import Button from "../../../components/Elements/Button"
import ButtonLite from "../../../components/Elements/ButtonLite"
import QuantitySelect from "../../../components/Elements/QuantitySelect"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import FloatingQuantitySelect from "../../../components/Elements/Form/FloatingQuantitySelect"
import FloatingInput from "../../../components/Elements/Form/FloatingInput"
import FloatingInteger from "../../../components/Elements/Form/FloatingInteger"
import Modal from "../../../components/Modal/Modal"
// import CountUp from "react-countup"
// import { Range, getTrackBackground } from "react-range"
import NotificationContext from "../../../context/Notification/NotificationContext"
// import Accordion from "../../../components/Accordion/Accordion"
import DataContext from "../../../context/Data/DataContext"
// import Tags from "../../../components/Elements/Tags"
// import Tag from "../../../components/Elements/Tag"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import { AllocationsTable } from "../../../components/Table/AllocationsTable"
import Skeleton from "../../../components/Skeleton"
// import CircleAvatar from "../../../components/Elements/CircleAvatar"
// import ImageCardSmall from "../../../components/Elements/ImageCardSmall"
// import { LockClosed } from "@heroicons/react/outline"
// import InventorySegmentsRestrictions from "../../../components/InventorySegmentsRestrictions"
// import InventorySubmission from "../../../components/InventorySubmission"
import IconClose from "../../../components/Elements/IconClose"
import TableFilter from "../../../components/Table/Inventory/StockList/TableFilter"
import Pagination from "../../../components/Pagination"
import AllocateModalTopMeta from "./AllocateModalTopMeta"
import useIsMount from "../../../utils/useIsMount"
// import { is } from "date-fns/locale"
const Allocations = props => {
  const { toggleNotification } = useContext(NotificationContext)
  const { allocationState, setAllocationState, errors, total } = props
  // const [create, setCreate] = useState(false)
  // console.log(props, "props")
  const { myUserGroups, myUserGroupsAsOptions, universalUserGroups } =
    useContext(DataContext).data

  // const increment = k => {
  //   return
  // }
  // const decrement = k => {
  //   return
  // }

  console.log(allocationState, "internal")

  const addAnother = () => {
    if (parseInt(total) === 0) return

    // set the default allocation to 10 unless the total stock remaining is less than 10, then set it to the total stock remaining in the allocationState is less than 10, then set it to the total stock remaining
    let defaultAllocation = 10
    if (parseInt(total) < 10) {
      defaultAllocation = parseInt(total)
    } else {
      let totalAllocated = 0
      allocationState.forEach(item => {
        totalAllocated += parseInt(item.aloc)
      })
      if (totalAllocated + 10 > parseInt(total)) {
        defaultAllocation = parseInt(total) - totalAllocated
      }
    }

    setAllocationState(prevState => [
      ...prevState,
      {
        roles: [],
        aloc: defaultAllocation.toString(),
        type: "can_order",
        restriction_number: "5",
        restriction_frequency: "daily",
      },
    ])
  }

  const onRemove = k => {
    if (parseInt(total) === 0) return

    let tmpArr = JSON.parse(JSON.stringify([...allocationState]))
    tmpArr.splice(k, 1)
    setAllocationState(tmpArr)
  }

  const updateFieldChanged = (stateIndex, fieldName, fieldValue) => {
    if (parseInt(total) === 0) return

    if (fieldName === "aloc") {
      // check that total allocated does not exceed total in stock
      let totalAllocated = 0
      allocationState.forEach((item, k) => {
        if (k !== stateIndex) {
          totalAllocated += parseInt(item.aloc)
        }
      })
      totalAllocated += parseInt(fieldValue)
      if (totalAllocated > parseInt(total)) {
        toggleNotification({
          error: true,
          content: "You've reached the maximum stock available.",
        })

        return
      }
    }

    let newArr = [...allocationState]
    newArr[stateIndex][fieldName] = fieldValue
    setAllocationState(newArr)
  }

  const typesOptions = [
    { value: "can_order", label: "Can Order" },
    { value: "must_request", label: "Must Request" },
  ]

  const frequencyOptions = [
    { value: "daily", label: "Per day" },
    { value: "weekly", label: "Per week" },
    // { value: "biweekly", label: "Biweekly" },
    { value: "monthly", label: "Per month" },
    { value: "yearly", label: "Per year" },
  ]

  return (
    <div className="relative">
      {parseInt(total) === 0 && (
        <div
          className="absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,.4)] z-[1] rounded-[8px]"
          css={css`
            backdrop-filter: blur(4px);
          `}
        >
          <div className="bg-white rounded-[8px] px-8 py-10 absolute top-[100px] mx-auto left-0 right-0 w-[50%] shadow-[0_10px_30px_rgba(0,0,0,.2)]">
            <h2 className="text-strong-25 !text-left">Sorry!</h2>
            <p className="text-reg-16 mb-4">
              This item must be in stock before allocation inventory.
            </p>
          </div>
        </div>
      )}
      <div
        className={
          parseInt(total) === 0 ? `p-6 h-[400px] overflow-hidden` : `p-0`
        }
      >
        <p className="text-reg-16 mb-8">
          Select 1 or more User Segments and assign their Per Segment
          Availability and Per Location Order Limits. Allocations are
          prioritized from most specific User Segment to least; Users, Stores,
          Companies and then Regions.
        </p>
        <AllocationsTable
          columns={[
            "",
            "Per Segment Availability",
            "Per Location Order Limits",
            "",
          ]}
          sm
          overflowVisible
        >
          {allocationState.map((item, k) => {
            // const { title } = myUserGroups.filter(f => parseInt(item.roles[0]) === parseInt(f.id))[0] ? myUserGroups.filter(f => parseInt(item.roles[0]) === parseInt(f.id))[0] : ''
            // console.log(k)

            // console.log(myUserGroupsAsOptions, 'sss')
            // console.log(myUserGroupsAsOptions.filter(f => parseInt(f.value) === parseInt(item.roles[0]))[0])

            return (
              <Tr key={k}>
                <Td className="min-w-[220px] !pr-0">
                  <div className="flex flex-col">
                    <FloatingSelect
                      label="User Segment"
                      name="User Segment"
                      options={[
                        ...universalUserGroups.map(item => {
                          let groupType = ""
                          if (item.companies.length)
                            groupType = "Retail companies"
                          if (item.stores.length) groupType = "Retail stores"
                          if (item.regions.length)
                            groupType = "Regional retailers"
                          if (item.users.length) groupType = "Company users"
                          return Object.assign({
                            value: item.id,
                            label: item.title,
                            type: groupType,
                          })
                        }),
                        ...myUserGroups.map(item => {
                          let groupType = ""
                          if (item.field_group_companies.length)
                            groupType = "Retail companies"
                          if (item.field_group_stores.length)
                            groupType = "Retail stores"
                          if (item.field_group_regions.length)
                            groupType = "Regional retailers"
                          if (item.field_group_users.length)
                            groupType = "Company users"
                          return Object.assign({
                            value: item.id,
                            label: item.title,
                            type: groupType,
                          })
                        }),
                      ]}
                      subtitleSelector="type"
                      value={
                        item.roles.length
                          ? [
                              ...universalUserGroups.map(i =>
                                Object.assign({ value: i.id, label: i.title })
                              ),
                              ...myUserGroupsAsOptions,
                            ].filter(
                              f => parseInt(f.value) === parseInt(item.roles[0])
                            )[0]
                          : null
                      }
                      className="!mb-0"
                      onChange={v => {
                        updateFieldChanged(
                          k,
                          "roles",
                          v?.value ? [v.value] : []
                        )
                      }}
                      error={
                        item.roles.length === 0 || errors.has(k)
                          ? { message: "This field is required." }
                          : false
                      }
                      required={true}
                    />
                  </div>
                </Td>

                <Td>
                  <div className="flex space-x-1">
                    <FloatingQuantitySelect
                      flush
                      decrement={() => {
                        if (parseInt(item.aloc) > 0)
                          updateFieldChanged(k, "aloc", parseInt(item.aloc) - 1)
                      }}
                      increment={() => {
                        if (true)
                          updateFieldChanged(k, "aloc", parseInt(item.aloc) + 1)
                      }}
                      count={item.aloc}
                      label={"Qty"}
                      onChange={v => {
                        if (isNaN(v)) updateFieldChanged(k, "aloc", parseInt(0))
                        else updateFieldChanged(k, "aloc", parseInt(v))
                      }}
                    />
                    <FloatingSelect
                      flush
                      className="!mb-0"
                      label="Type"
                      name="type"
                      options={typesOptions}
                      onChange={v => {
                        updateFieldChanged(k, "type", v.value || null)
                      }}
                      required={true}
                      value={
                        typesOptions
                          .map(i => i.value)
                          .includes(allocationState[k].type)
                          ? typesOptions.filter(
                              f => allocationState[k].type === f.value
                            )[0]
                          : typesOptions[0]
                      }
                    />
                  </div>
                </Td>
                <Td>
                  <div className="flex space-x-1">
                    <FloatingQuantitySelect
                      flush
                      decrement={() => {
                        if (parseInt(item.restriction_number) > 0)
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(item.restriction_number) - 1
                          )
                      }}
                      increment={() => {
                        if (true)
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(item.restriction_number) + 1
                          )
                      }}
                      onChange={v => {
                        if (isNaN(v))
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(0)
                          )
                        else
                          updateFieldChanged(
                            k,
                            "restriction_number",
                            parseInt(v)
                          )
                      }}
                      count={parseInt(item.restriction_number)}
                      label={"Qty"}
                    />
                    <FloatingSelect
                      flush
                      className="!mb-0"
                      options={frequencyOptions}
                      onChange={v => {
                        updateFieldChanged(k, "restriction_frequency", v.value)
                      }}
                      value={
                        frequencyOptions
                          .map(i => i.value)
                          .includes(allocationState[k].restriction_frequency)
                          ? frequencyOptions.filter(
                              f =>
                                allocationState[k].restriction_frequency ===
                                f.value
                            )[0]
                          : frequencyOptions[0]
                      }
                      label="Frequency"
                      required={true}
                    />
                  </div>
                </Td>
                <Td className={"!p-[0]"}>
                  <IconClose
                    callback={() => onRemove(k)}
                    small
                    height={2}
                    width={2}
                  />
                </Td>
              </Tr>
            )
          })}
        </AllocationsTable>

        <div className="mt-3 mb-8 max-w-[200px]">
          <ButtonLite red inline callback={() => addAnother()}>
            Add another Allocation
          </ButtonLite>
        </div>
      </div>
    </div>
  )
}

const TRow = ({ name, val, number, selectval, onChange, showDefault }) => {
  const [show, setShow] = useState(
    number && selectval ? false : showDefault ? false : true
  )
  const [count, setCount] = useState(number ? number : 0)
  // console.log(selectOptions)
  const [select, setSelect] = useState(
    selectval ? selectOptions.filter(v => v.value === selectval)[0] : null
  )
  // console.log(name, number, select, show, selectval, showDefault)
  useEffect(() => {
    onChange({
      restriction_number: count,
    })
  }, [count])
  return (
    <Tr>
      <Td>
        <div className="flex flex-col">
          {name}
          <span className="text-med-10 uppercase">{val} units</span>
        </div>
      </Td>
      {show ? (
        <Td col>
          <div className="flex items-center">
            <input
              id={`${name}`}
              // defaultValue={option.value}
              type="checkbox"
              defaultChecked={true}
              onChange={() => setShow(!show)}
              className="h-4 w-4 border-[#EBEBEB] rounded rounded-[4px] text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
              // {...register(`${name}.${optionIdx}.value`)}
            />
            <label
              htmlFor={`${name}`}
              className="ml-3 text-reg-16"
              css={css`
                &&& {
                  color: #222;
                }
              `}
            >
              Unlimited
            </label>
          </div>
        </Td>
      ) : (
        <>
          <Td>
            <QuantitySelect
              decrement={() => setCount(v => v - 1)}
              increment={() => setCount(v => v + 1)}
              count={count}
              small
            />
            <div className="flex items-center mt-2">
              <input
                id={`${name}`}
                // defaultValue={option.value}
                type="checkbox"
                defaultChecked={false}
                onChange={() => setShow(!show)}
                className="h-3 w-3 border-[#EBEBEB] rounded rounded-[4px] text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                // {...register(`${name}.${optionIdx}.value`)}
              />
              <label
                htmlFor={`${name}`}
                className="ml-3  text-reg-16"
                style={{ fontSize: "12px" }}
                css={css`
                  &&& {
                    color: #222;
                  }
                `}
              >
                Unlimited
              </label>
            </div>
          </Td>
          <Td>
            <FloatingSelect
              className="!mb-0"
              options={selectOptions}
              label="Frequency"
              sm
              onChange={v => {
                setSelect(v)
                onChange({
                  restriction_frequency: v?.value,
                })
              }}
              value={select}
            />
          </Td>
        </>
      )}
    </Tr>
  )
}
const selectOptions = [
  { value: "daily", label: "Per day" },
  { value: "weekly", label: "Per week" },
  { value: "biweekly", label: "Biweekly" },
  { value: "monthly", label: "Per month" },
  { value: "forever", label: "Forever" },
]

const AllocateModal = ({ token, current, value, maxV, onSave }) => {
  // const [values, setValues] = useState([value])
  // const { locked } = current
  const { toggleNotification } = useContext(NotificationContext)
  const { myUserGroups, universalUserGroups } = useContext(DataContext).data

  const [body, setBody] = useState(current)
  const [allocationState, setAllocationState] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState([])
  const [segmentErrors, setSegmentErrors] = useState(new Set())
  const [internalAloc, setInternalAloc] = useState(0)
  const [retailersAloc, setRetailersAloc] = useState(0)
  const [stock, setStock] = useState(0)

  const warehouseTypes = [
    { label: "O2O Warehouse", value: "o2o" },
    { label: "In-house", value: "in-house" },
    { label: "Third party", value: "third-party" },
  ]

  console.log(allocationState, "for internal")
  console.log(body, "for internal")

  const onSaveClick = async () => {
    setIsLoading(true)

    if (formErrors.length === 0 && segmentErrors.size === 0) {
      let res = await updateUserStock(token, current.id, body)
      if (res.status === 200) {
        const response = await res.json()
        onSave(response)
      } else {
        toggleNotification({
          content: "Oops, that failed. Please try again.",
          error: true,
        })
      }
      setIsLoading(false)
    } else {
      toggleNotification({
        content: "Please complete all fields.",
        error: true,
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (current && current.segments) {
      setAllocationState(current.segments)
    }
    console.log(current.segments)
  }, [current])
  useEffect(() => {
    setBody(prevState => ({
      ...prevState,
      segments: allocationState,
    }))
  }, [allocationState])

  useEffect(() => {
    if (body.fulfillment_type !== "o2o") {
      setBody(prevState => ({
        ...prevState,
        fulfillment: {
          ...prevState.fulfillment,
          name: null,
          stock: null,
        },
      }))
    }
  }, [body.fulfillment_type])

  useEffect(() => {
    const x = []
    let fields = ["notif_total", "notif_rep", "notif_ret", "fulfillment_type"]
    if (body.fulfillment_type === "in-house")
      fields = [...fields, "fulfillment_name", "stock_in_house"]
    if (body.fulfillment_type === "third-party")
      fields = [...fields, "fulfillment_name", "stock_third_party"]
    Object.keys(body)
      .filter(f => fields.includes(f))
      .map((item, k) => {
        if (
          item == "stock_in_house" &&
          parseInt(body[item]) <
            body.segments
              .map(i => i.aloc)
              .reduce((partialSum, a) => partialSum + a, 0)
        ) {
          x.push(item)
        } else if (
          item == "stock_third_party" &&
          parseInt(body[item]) <
            body.segments
              .map(i => i.aloc)
              .reduce((partialSum, a) => partialSum + a, 0)
        ) {
          x.push(item)
        } else if (body[item] === null || body[item] === "") {
          x.push(item)
        }
      })

    const y = new Set()
    body.segments.map((f, k) => {
      if (f.roles.length === 0) y.add(k)
    })
    setFormErrors(x)
    setSegmentErrors(y)
  }, [current, body])

  const [prevSegmentsO2O, setPrevSegmentsO2O] = useState([])
  const [prevSegmentsInHouse, setPrevSegmentsInHouse] = useState([])
  const [prevSegmentsThirdParty, setPrevSegmentsThirdParty] = useState([])

  const tabs = [
    {
      name: "Fulfillment",
      content: (
        <>
          <FloatingSelect
            value={
              body.fulfillment_type
                ? warehouseTypes.filter(
                    f => f.value === body.fulfillment_type
                  )[0]
                : null
            }
            label="Warehouse Type"
            name="type"
            description={"Select who is fulfilling orders for this asset..."}
            required={false}
            options={warehouseTypes}
            onChange={e => {
              console.log(e, "e")

              if (body.fulfillment_type === "o2o") {
                setPrevSegmentsO2O(allocationState)
              }
              if (body.fulfillment_type === "in-house") {
                setPrevSegmentsInHouse(allocationState)
              }
              if (body.fulfillment_type === "third-party") {
                setPrevSegmentsThirdParty(allocationState)
              }

              const obj = { fulfillment_type: e?.value || null }

              if (e?.value === "o2o" && prevSegmentsO2O.length) {
                setAllocationState(prevSegmentsO2O)
              } else if (
                e?.value === "in-house" &&
                prevSegmentsInHouse.length
              ) {
                setAllocationState(prevSegmentsInHouse)
              } else if (
                e?.value === "third-party" &&
                prevSegmentsThirdParty.length
              ) {
                setAllocationState(prevSegmentsThirdParty)
              } else {
                setAllocationState([])
              }

              setBody(prevState => ({
                ...prevState,
                ...obj,
                // fulfillment_type: e?.value || null,
              }))
            }}
            error={
              formErrors.includes("fulfillment_type")
                ? { message: "This field must have a value." }
                : false
            }
          />
          {body.fulfillment_type === "o2o" ? (
            <>
              {" "}
              <FloatingInput
                value={"O2O Warehouse"}
                subdued
                disabled={body.fulfillment_type === "o2o"}
                label="Warehouse Name"
                name="warehouse_name"
                required={false}
                description={
                  "Enter the name of the warehouse or fulfillment location for your records"
                }
                onChange={e => {}}
              />
              <FloatingInteger
                value={body.stock_o2o}
                subdued
                disabled={body.fulfillment_type === "o2o"}
                label="In Stock Quantity (O2O Warehouse)"
                name="stock_o2o"
                required={false}
                description={
                  "Enter total quantity you have on hand, not just the quantities you want available."
                }
                onChange={e => {}}
              />
            </>
          ) : (
            <>
              <FloatingInput
                value={body.fulfillment_name}
                disabled={body.fulfillment_type === "o2o"}
                label="Warehouse Name"
                name="warehouse_name"
                required={false}
                description={
                  "Enter the name of the warehouse or fulfillment location for your records"
                }
                onChange={e => {
                  setBody(prevState => ({
                    ...prevState,
                    fulfillment_name: e.target.value || null,
                  }))
                }}
                error={
                  formErrors.includes("fulfillment_name")
                    ? { message: "This field must have a value." }
                    : false
                }
              />
              {body.fulfillment_type === "in-house" && (
                <FloatingInteger
                  value={body.stock_in_house}
                  label="In Stock Quantity (In house)"
                  name="stock_in_house"
                  required={false}
                  description={
                    "Enter total quantity you have on hand, not just the quantities you want available."
                  }
                  onChange={e => {
                    setBody(prevState => ({
                      ...prevState,
                      stock_in_house: parseInt(e.target.value) || null,
                    }))
                  }}
                  error={
                    formErrors.includes("stock_in_house")
                      ? {
                          message:
                            body.stock_in_house <
                            body.segments
                              .map(i => i.aloc)
                              .reduce((partialSum, a) => partialSum + a, 0)
                              ? "Your total allocations equal more than this quantity. Either increase the quantity in-stock, or modify your allocations."
                              : "This field must have a value.",
                        }
                      : false
                  }
                />
              )}
              {body.fulfillment_type === "third-party" && (
                <FloatingInteger
                  value={body.stock_third_party}
                  label="In Stock Quantity (Third party)"
                  name="stock_third_party"
                  required={false}
                  description={
                    "Enter total quantity you have on hand, not just the quantities you want available."
                  }
                  onChange={e => {
                    setBody(prevState => ({
                      ...prevState,
                      stock_third_party: parseInt(e.target.value) || null,
                    }))
                  }}
                  error={
                    formErrors.includes("stock_third_party")
                      ? {
                          message:
                            body.stock_third_party <
                            body.segments
                              .map(i => i.aloc)
                              .reduce((partialSum, a) => partialSum + a, 0)
                              ? "Your total allocations equal more than this quantity. Either increase the quantity in-stock, or modify your allocations."
                              : "This field must have a value.",
                        }
                      : false
                  }
                />
              )}
              {/* <FloatingInteger
                value={body.fulfillment_stock}
                disabled={body.fulfillment_type === "o2o"}
                label="In Stock Quantity"
                name="in_stock_quantity"
                required={false}
                description={
                  "Enter total quantity you have on hand, not just the quantities you want available."
                }
                onChange={e => {
                  console.log(e, "e")
                  setBody(prevState => ({
                    ...prevState,
                    fulfillment_stock: e.target.value || null,
                  }))
                }}
                error={
                  formErrors.includes("fulfillment_stock")
                    ? { message: "This field must have a value." }
                    : false
                }
              /> */}
            </>
          )}

          <p className="red">
            Last Inventory Submission: N/A
            <br />
            Status: N/A
          </p>
        </>
      ),
    },
    {
      name: "Allocations",
      content: (
        <Allocations
          total={stock}
          errors={segmentErrors}
          allocationState={allocationState}
          setAllocationState={setAllocationState}
        />
      ),
    },
    {
      name: "Notifications",
      content: (
        <>
          <p className="text-reg-16 mb-8">
            Please enter the threshold values for when you want notifications to
            be sent to your company.
          </p>
          <div className="flex flex-col md:flex-row md:items-center space-x-1">
            <p className="mb-0 grow md:w-[30%] text-reg-14 !leading-[1.4]">
              When inventory goes below...
            </p>
            <FloatingInteger
              value={body.notif_total}
              className="md:w-[23.3%] !mb-0"
              label="Total Qty"
              name="notif_total_qty"
              onChange={e =>
                setBody(prevState => ({
                  ...prevState,
                  notif_total: e.target.value,
                }))
              }
              error={
                formErrors.includes("notif_total")
                  ? { message: "This field must have a value." }
                  : false
              }
            />
            <FloatingInteger
              value={body.notif_rep}
              className="md:w-[23.3%] !mb-0"
              label="Internal Qty"
              name="notif_internal_qty"
              onChange={e =>
                setBody(prevState => ({
                  ...prevState,
                  notif_rep: e.target.value,
                }))
              }
              error={
                formErrors.includes("notif_rep")
                  ? { message: "This field must have a value." }
                  : false
              }
            />
            <FloatingInteger
              value={body.notif_ret}
              className="md:w-[23.3%] !mb-0"
              label="Retailer Qty"
              name="notif_retail_qty"
              onChange={e =>
                setBody(prevState => ({
                  ...prevState,
                  notif_ret: e.target.value,
                }))
              }
              error={
                formErrors.includes("notif_ret")
                  ? { message: "This field must have a value." }
                  : false
              }
            />
          </div>
        </>
      ),
    },
  ]

  const returnGroupType = id => {
    let all = [
      ...universalUserGroups.map(item => {
        let groupType = ""
        if (item.companies.length) groupType = "Retail companies"
        if (item.stores.length) groupType = "Retail stores"
        if (item.regions.length) groupType = "Regional retailers"
        if (item.users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
      ...myUserGroups.map(item => {
        let groupType = ""
        if (item.field_group_companies.length) groupType = "Retail companies"
        if (item.field_group_stores.length) groupType = "Retail stores"
        if (item.field_group_regions.length) groupType = "Regional retailers"
        if (item.field_group_users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
    ]

    let role = all.filter(f => parseInt(f.value) === parseInt(id))[0]
    return role?.type || null
  }

  useEffect(() => {
    console.log(body.stock_in_house, "xxx")
    if (body.fulfillment_type === "o2o") setStock(body.stock_o2o)
    if (body.fulfillment_type === "in-house") setStock(body.stock_in_house)
    if (body.fulfillment_type === "third-party")
      setStock(body.stock_third_party)
  }, [body])
  const [unAloc, setunAloc] = useState(0)

  useEffect(() => {
    if (stock > 0) {
      let internalaloc = 0
      let retailersaloc = 0
      allocationState.map(v => {
        if (v.roles.length) {
          let type = returnGroupType(v.roles[0])
          if (type === "Company users") internalaloc += parseInt(v.aloc)
          else retailersaloc += parseInt(v.aloc)
        }
      })
      setRetailersAloc(retailersaloc)
      setInternalAloc(internalaloc)

      setunAloc(stock - (internalaloc + retailersaloc))
    }
  }, [allocationState, stock])

  return (
    <>
      <AllocateModalTopMeta
        title={current.title}
        sku={current.sku}
        brand={current.brand}
        cover={current.cover}
      />
      <div className="flex mt-6 mb-6 items-end border border-1 border-[#EBEBEB] rounded-[8px] px-4 py-4">
        <div className="mr-6 mb-2">
          <span className="text-strong-20 !leading-[1] mr-1">{stock}</span>
          <span>in stock</span>
        </div>
        <div className="grow flex items-center">
          <div
            className={`w-[${
              stock === 0 ? 33.3 : (internalAloc / stock) * 100
            }%] mr-1`}
            style={{
              color: "#0033A1",
              width: `${stock === 0 ? 33.3 : (internalAloc / stock) * 100}%`,
              minWidth: "11%",
            }}
          >
            <div className="text-[14px]">for Internal</div>
            <div className="w-[100%] rounded-l-lg h-[40px] bg-[#0033A1] text-center text-white p-[7px]">
              {internalAloc}
            </div>
          </div>
          <div
            className={`w-[${
              stock === 0 ? 33.3 : (retailersAloc / stock) * 100
            }%] mr-1`}
            style={{
              color: "#009D4F",
              width: `${stock === 0 ? 33.3 : (retailersAloc / stock) * 100}%`,
              minWidth: "11%",
            }}
          >
            <div className="text-[14px]">for Retailers</div>
            <div className="w-[100%] h-[40px] bg-[#009D4F] text-center text-white p-[7px]">
              {retailersAloc}
            </div>
          </div>
          <div
            className={`w-[${stock === 0 ? 33.3 : (unAloc / stock) * 100}%] `}
            style={{
              color: "rgba(0,0,0,.3)",
              width: `${stock === 0 ? 33.3 : (unAloc / stock) * 100}%`,
            }}
          >
            <div className="text-[14px]">Unallocated</div>
            <div
              className="w-[100%] rounded-r-lg h-[40px] bg-[#EBEBEB] text-center p-[7px]"
              style={{ color: "rgba(0,0,0,.3)" }}
            >
              {unAloc}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <Tabs tabs={tabs} />
      </div>
      <Button red onClick={onSaveClick} loading={isLoading}>
        Save changes
      </Button>
    </>
  )
}
function InventoryList(props) {
  const isMount = useIsMount()
  const { location, updateActiveInventorySubmission } = props
  const { state } = useContext(StateContext)
  const [list, setList] = useState(null)
  const { currentUserCompaniesAsOp } = useContext(DataContext).data
  // const [select, setSelect] = useState(
  //   currentUserCompaniesAsOp ? currentUserCompaniesAsOp[0] : false
  // )
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [current, setCurrent] = useState(null)

  const [isLoading, setIsLoading] = useState(true)
  const [filters, setFilters] = useState({ page: 1 })
  const [values, setValues] = useState([0])
  const [max, setMAX] = useState(0)
  // const [loadMore, setLoadMore] = useState(false)
  // const [hasMore, setHasMore] = useState(false)
  const [submitNewRequest, setSubmitNewRequest] = useState(false)
  const [submitNewRequestAsset, setSubmitNewRequestAsset] = useState(null)

  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  // const [showPagination, setShowPagination] = useState(true)
  const [perPage, setPerPage] = useState(50)
  // const [page, setPage] = useState(1)
  const { toggleNotification, notificationIsVisible } =
    useContext(NotificationContext)

  const scrollRef = useRef()
  console.log({ current, list }, "current")

  useEffect(async () => {
    if (isMount && list === null) {
      try {
        const data = await postStockListing(state.token, filters)
        setList(data.results)
        setTotal(data.total)
      } catch (e) {
        toggleNotification({
          error: true,
          content: "An unexpected error occurred.",
        })
        console.log(e)
      }

      // setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    let pages = Math.ceil(total / perPage)
    setTotalPage(pages)
    console.log("hi")
  }, [total])

  useEffect(async () => {
    setIsLoading(true)
    if (!isMount && list !== null) {
      try {
        const data = await postStockListing(state.token, filters)
        setList(data.results)
        setTotal(data.total)
      } catch (e) {
        toggleNotification({
          error: true,
          content: "An unexpected error occurred.",
        })
        console.log(e)
      }
    }
  }, [filters])

  useEffect(() => {
    if (isLoading === true) setIsLoading(false)
  }, [list])

  const onChangeFilters = f => {
    setFilters(prevState => ({
      ...prevState,
      page: 1,
      ...f,
    }))
  }

  const onEditClick = v => {
    let f = list.filter(c => c.id == v)[0]
    setCurrent(f)
    setEditCurrent(v)
    setOpen(true)
  }

  const onAddClick = v => {
    console.log("onaddclick")
    let f = list.filter(c => c.id == v)[0]

    updateActiveInventorySubmission({
      isNew: true,
      data: {
        assets: [
          {
            asset: {
              value: f.id,
              label: f.title,
            },
            brand: {
              value: f.brand.id,
              label: f.brand.name,
            },
          },
        ],
        company: {
          id: f.company.id,
        },
      },
    })
    setSubmitNewRequestAsset(f)
    setSubmitNewRequest(true)
  }
  const onSave = (data, v) => {
    if (v === "added") {
      let newList = [...list, data]
      setList([...newList])
      setOpenAfterSave(true)
      setContentAfterSave("Products Added Sucessfully")
    } else if (v === "edited") {
      setList([...data])
      setOpen(false)
      setEditCurrent(null)
      setOpenAfterSave(true)

      setContentAfterSave("Edited Added Sucessfully")
    } else {
      setOpen(false)
      setEditCurrent(null)
      setOpenAfterSave(true)
      setContentAfterSave(data + " Failed")
    }
  }

  useEffect(async () => {
    let editId = location?.state?.editId
    if (editId && list.length) {
      onEditClick(editId)
    }
  }, [list])

  const onPageChange = v => {
    setIsLoading(true)
    setFilters(prevFilters => ({ ...prevFilters, page: v }))
  }
  return (
    <div ref={scrollRef}>
      <TableFilter onChange={onChangeFilters} list={list} />
      {list === null || isLoading === true ? (
        <Skeleton table />
      ) : list.length > 0 ? (
        <>
          <StockListTable
            data={list}
            onEditClick={onEditClick}
            onAddClick={onAddClick}
          />
          <Pagination
            notLink
            totalPages={totalPage}
            page={filters.page}
            scrollRef={scrollRef}
            setPage={onPageChange}
          />
          {/* <div className="load-more-area text-center my-6" ref={loadRef}>
            {hasMore ? (
              <div className="w-full overflow-hidden flex flex-col items-center justify-center">
                <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
              </div>
            ) : (
              <p className="text-reg-14">No more activity to show</p>
            )}
          </div> */}
        </>
      ) : (
        "No items to display"
      )}
      {current && (
        <Modal
          isOpen={open}
          closeModal={() => {
            if (!notificationIsVisible) {
              setOpen(false)
              setEditCurrent(null)
              setCurrent(null)
            }
          }}
          title={"Adjust Inventory"}
          md
        >
          <AllocateModal
            value={values[0]}
            maxV={max}
            current={current}
            token={state.token}
            onSave={async v => {
              setOpen(false)
              let fIndex = list.findIndex(c => c.id == editCurrent)
              let tmpArr = [...list]
              tmpArr[fIndex] = { ...tmpArr[fIndex], ...v }
              setList([...tmpArr])
              setCurrent(null)
              setEditCurrent(null)
              toggleNotification({
                content: `Inventory settings saved successfully.`,
              })
            }}
          />
        </Modal>
      )}
    </div>
  )
}
export default InventoryList